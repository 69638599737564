import gql from 'graphql-tag';

import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { DISPLAYED_VICTORIES } from 'src/app/pages/homepage/shared/consts';

import type { FeaturedVictoriesQuery, FeaturedVictoriesQueryVariables } from './getFeaturedVictories.graphql';

export type FeaturedVictoryPetition = FeaturedVictoriesQuery['featuredVictories'][0];
export type FeaturedVictoryOrganization = NonNullable<FeaturedVictoryPetition['organization']>;
export type FeaturedVictoryUser = FeaturedVictoryPetition['user'];
export type FeaturedVictoryUserPhoto = NonNullable<FeaturedVictoryUser['photo']>;
export type FeaturedVictoryOrganizationPhoto = NonNullable<FeaturedVictoryOrganization['photo']>;
export type FeaturedVictoryPhotoSize = FeaturedVictoryUserPhoto['userSmall'];
export type FeaturedVictoryCountry = NonNullable<FeaturedVictoryUser['country']>;

// eslint-disable-next-line max-lines-per-function
export async function getFeaturedVictories({
	gql: { fetch },
}: UtilityContext): Promise<readonly FeaturedVictoryPetition[]> {
	const { data } = await fetch<FeaturedVictoriesQuery, FeaturedVictoriesQueryVariables>({
		query: gql`
			query FeaturedVictories($limit: Int!) {
				featuredVictories(limit: $limit, offset: 0) {
					id
					slug
					displayTitle
					description
					mediaText
					mediaUrl
					signatureState {
						signatureCount {
							displayed
							displayedLocalized
						}
					}
					user {
						__typename
						id
						displayName
						city
						country {
							countryCode
						}
						photo {
							id
							userSmall {
								url
								processing
							}
						}
					}
					organization {
						__typename
						id
						name
						photo {
							id
							organizationSmall {
								url
								processing
							}
						}
					}
					photo {
						id
						petitionMedium {
							url
							processing
						}
						petitionSmall {
							url
							processing
						}
					}
				}
			}
		`,
		variables: {
			limit: DISPLAYED_VICTORIES,
		},
	});
	return data?.featuredVictories || [];
}
