import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { FeaturedTopic } from './getFeaturedTopicsByLocale';
import { getFeaturedTopicsByLocale } from './getFeaturedTopicsByLocale';
import type { FeaturedVictoryPetition } from './getFeaturedVictories';
import { getFeaturedVictories } from './getFeaturedVictories';
import type { TotalUserCountResponse } from './getTotalUserCount';
import { getTotalUserCount } from './getTotalUserCount';

export type HomepagePageData = {
	userCount: TotalUserCountResponse | undefined;
	featuredVictories: readonly FeaturedVictoryPetition[];
	featuredTopics: readonly FeaturedTopic[];
};

export async function getHomepagePageData(utilityContext: UtilityContext): Promise<HomepagePageData> {
	const [userCount, featuredVictories, featuredTopics] = await Promise.all([
		getTotalUserCount(utilityContext),
		getFeaturedVictories(utilityContext),
		getFeaturedTopicsByLocale(utilityContext),
	]);

	return {
		userCount,
		featuredVictories,
		featuredTopics,
	};
}

export type {
	FeaturedVictoryCountry,
	FeaturedVictoryOrganization,
	FeaturedVictoryOrganizationPhoto,
	FeaturedVictoryPetition,
	FeaturedVictoryPhotoSize,
	FeaturedVictoryUser,
	FeaturedVictoryUserPhoto,
} from './getFeaturedVictories';
export type { FeaturedTopic } from './getFeaturedTopicsByLocale';
