import { useCallback } from 'react';

import uniq from 'lodash/fp/uniq';

import { useI18n } from '@change-corgi/core/react/i18n';
import { iconNewspaper } from '@change-corgi/design-system/icons';

import type { FeedItemMediaMention } from '../../../api/types';
import type { PetitionContext } from '../types';

const getUniqueMediaProviders = (feedItem: FeedItemMediaMention): string[] => {
	const petitionUpdates = feedItem.node?.petitionUpdatesConnection?.nodes
		? [...feedItem.node.petitionUpdatesConnection.nodes]
		: [];

	return uniq(
		petitionUpdates
			.filter((item) => 'newsSource' in item)
			.map((item) => item.newsSource?.name)
			.filter((providerName) => providerName !== undefined && providerName !== null),
	);
};

export function useMediaMention(): (feedItem: FeedItemMediaMention) => PetitionContext | undefined {
	const { translate, translatePlural } = useI18n();

	return useCallback(
		(feedItem) => {
			const mediaProviders = getUniqueMediaProviders(feedItem);
			const mediaProviderCount = mediaProviders.length;
			if (mediaProviderCount === 0) return undefined;

			const hasMultipleProviders = mediaProviders.length > 1;
			const featuredMedia = mediaProviders[mediaProviders.length - 1];

			const label = hasMultipleProviders
				? translatePlural('fe.components.discover_feed.media_mention_other', mediaProviderCount - 1, {
						media_name: featuredMedia,
						num: mediaProviderCount - 1,
					})
				: translate('fe.components.discover_feed.media_mention_one', { media_name: featuredMedia });

			return {
				href: `/p/${encodeURIComponent(feedItem.node.slug)}?source_location=discover_feed`,
				icon: iconNewspaper,
				label,
				fullHeaderLink: true,
				contextType: 'media_mention',
			};
		},
		[translate, translatePlural],
	);
}
