import type { ComponentType, JSX, PropsWithChildren } from 'react';

import type { ErrorStateDetails } from 'src/app/shared/utils/async';
import { isError, isLoaded } from 'src/app/shared/utils/async';

import type { HomepagePageCombinedState, HomepagePagePrefetchedContext } from './hook';
import { usePageContext } from './hook';
import { HomepagePageDataContextProvider } from './pageData';
import { HomepagePageFcmContextProvider } from './pageFcm';

export type ErrorHandlerProps = { context: ErrorStateDetails<HomepagePageCombinedState> };

type Props = {
	prefetchedData: HomepagePagePrefetchedContext | undefined;
	errorHandler: ComponentType<ErrorHandlerProps>;
	loadingFallback: JSX.Element;
};

export function HomepagePageContextProvider({
	children,
	prefetchedData,
	errorHandler: ErrorHandler,
	loadingFallback,
}: PropsWithChildren<Props>): JSX.Element | null {
	const pageContextState = usePageContext(prefetchedData);

	if (isError(pageContextState)) {
		const { status, ...rest } = pageContextState;
		return <ErrorHandler context={rest} />;
	}
	if (!isLoaded(pageContextState)) return loadingFallback;

	const { pageData, pageFcm } = pageContextState;

	return (
		<HomepagePageDataContextProvider pageData={pageData}>
			<HomepagePageFcmContextProvider pageFcm={pageFcm}>{children}</HomepagePageFcmContextProvider>
		</HomepagePageDataContextProvider>
	);
}
