import { useCallback, useState } from 'react';
import type { JSX } from 'react';

import { Waypoint } from 'react-waypoint';

import type { WaypointCallbackArgs } from 'src/app/shared/components/layout/lazy';
import { useTrackPromotionImpression } from 'src/app/shared/hooks/promotion';

type PromotionImpressionTrackerProps = { petitionId: string; promotionToken: string; algorithm?: string };
export function PromotionImpressionTracker({
	petitionId,
	promotionToken,
	algorithm,
}: PromotionImpressionTrackerProps): JSX.Element {
	const [tracked, setTracked] = useState(false);

	const trackPromotedImpression = useTrackPromotionImpression({
		petitionId,
		promotionToken,
		algorithm,
		placement: 'discover_feed',
	});

	const onPositionChange = useCallback(
		({ currentPosition }: WaypointCallbackArgs) => {
			if (tracked || currentPosition === 'below') {
				return;
			}
			trackPromotedImpression();
			setTracked(true);
		},
		[trackPromotedImpression, tracked],
	);

	return <Waypoint onPositionChange={onPositionChange} />;
}
