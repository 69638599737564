import isUndefined from 'lodash/fp/isUndefined';
import omitBy from 'lodash/fp/omitBy';

import type { ViewTrackingProperties } from 'src/app/pages/homepage/shared/hooks';
import { useSession } from 'src/app/shared/hooks/session';

import type { FeedItem } from '../../api/types';
import { TYPENAME_TO_ITEM_TYPE } from '../../shared/consts';
import type { PetitionContext } from '../usePetitionContext/types';

export function useGetCardViewData(item: FeedItem, context: PetitionContext | undefined): ViewTrackingProperties {
	const session = useSession();
	const isNewUser = !!session?.user?.newUser;

	const eventAttributes = omitBy(isUndefined, {
		card_type: TYPENAME_TO_ITEM_TYPE[item.__typename],
		context_tag_id: context?.tagId,
		context_type: context?.contextType,
		new_user: isNewUser,
		organization_id: item.node.organization?.id,
		view_context: 'home_index',
	});
	return eventAttributes;
}
