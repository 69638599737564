import type { JSX } from 'react';

import type { FeaturedVictoryPetition } from 'src/app/pages/homepage/pageContext';
import { DEFAULT_IMAGE, Media } from 'src/app/shared/components/media';
import type { MediaProp } from 'src/app/shared/components/media';

function getMedia(victory: FeaturedVictoryPetition): MediaProp {
	if (victory.photo)
		return {
			url: victory.photo.petitionMedium.url,
			processing: victory.photo.petitionMedium.processing,
			type: 'photo',
		};
	return { url: DEFAULT_IMAGE, processing: false, type: 'photo' };
}

export function VictoryMedia({ victory }: { victory: FeaturedVictoryPetition }): JSX.Element {
	const media = getMedia(victory);

	return <Media media={media} shape="rectangle" />;
}
