import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import type { DiscoverFeedQuery, DiscoverFeedQueryVariables } from './discoverFeed.graphql';
import { DiscoverFeed } from './discoverFeed.graphql';
import type { DiscoverFeedReturn } from './types';

export async function getDiscoverFeed({
	after,
	utilityContext: {
		gql: { fetch },
	},
}: {
	after: string | null;
	utilityContext: UtilityContext;
}): Promise<DiscoverFeedReturn> {
	const { data } = await fetch<DiscoverFeedQuery, DiscoverFeedQueryVariables>({
		query: DiscoverFeed,
		variables: {
			after: after || null,
			types: ['STARTER', 'DM_RESPONSE', 'STAFF', 'FEATURED_NEWS'],
		},
		rejectOnError: true,
	});

	if (!data?.discoverFeedConnection) {
		throw new Error('No data returned');
	}
	return data.discoverFeedConnection;
}
