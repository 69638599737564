import type { JSX } from 'react';

import { VisuallyHidden } from '@change-corgi/design-system/a11y';
import { Box } from '@change-corgi/design-system/layout';

import { useHomepagePageData } from 'src/app/pages/homepage/pageContext';

import { SimpleCarousel } from './components/SimpleCarousel';

export function FeaturedVictoriesCarousel(): JSX.Element | null {
	const {
		data: { featuredVictories },
	} = useHomepagePageData();

	return (
		<Box as="section" aria-labelledby="featured-victories-carousel">
			<VisuallyHidden id="featured-victories-carousel" as="h2">
				Featured Victories
			</VisuallyHidden>
			<SimpleCarousel featuredVictories={featuredVictories} />
		</Box>
	);
}
