import { type JSX } from 'react';

import { stripHtmlTags } from '@change-corgi/core/html';
import { Translate } from '@change-corgi/core/react/i18n';
import { Link as RouterLink } from '@change-corgi/core/react/router';
import { Icon } from '@change-corgi/design-system/components/icon';
import { Avatar } from '@change-corgi/design-system/components/media';
import { iconSupervisorAccount } from '@change-corgi/design-system/icons';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { linkResetStyles } from '@change-corgi/design-system/reset';
import { Heading, Text } from '@change-corgi/design-system/typography';

import { VerticalEllipsis } from 'src/app/shared/components/layout/ellipsis';
import { ScrollableComponent } from 'src/app/shared/components/layout/lazy';
import { Media } from 'src/app/shared/components/media';

import type { FeedItem } from '../api/types';

type ContentProps = {
	href: string;
	description: string;
	photo: FeedItem['node']['photo'];
	title: string;
	userPhoto?: string;
	username: string;
	supporters: string;
	supportersShort: string;
	onClick: () => void;
};

const DESKTOP_CONTENT_HEIGHT = 150;

// FIXME split component
// eslint-disable-next-line max-lines-per-function
export function CardContent({
	description,
	photo,
	title,
	href,
	userPhoto,
	username,
	supporters,
	supportersShort,
	onClick,
}: ContentProps): JSX.Element {
	return (
		<RouterLink to={href} onClick={onClick} data-qa="content-link" sx={{ ...linkResetStyles }}>
			{/*
				NOTE: You should never need to have a desktop _and_ mobile view.
				This is a sign of bad, non-responsive UI. Since this component was
				included in a 1:1 migration, adjusting the UI was not allowed.
				Please don't copy this pattern elsewhere. Talk to your
				designer instead :)
			*/}
			{/* START: Desktop View */}
			<Flex sx={{ display: ['none', 'flex'] }} p={16}>
				<Text as="div" breakWord mr={[0, 16]}>
					<VerticalEllipsis
						height={DESKTOP_CONTENT_HEIGHT}
						fixedHeight
						watchResize
						ellipsis={
							<Text size="small" fontWeight="normal" color="primary-black">
								…{' '}
								<Text
									size="small"
									color="secondary-blue"
									sx={{
										textDecoration: 'underline',
										// eslint-disable-next-line @typescript-eslint/naming-convention
										'&:hover': { color: 'inherit' },
									}}
								>
									<Translate value="fe.pages.common.read_more" />
								</Text>
							</Text>
						}
					>
						<Heading as="h3" size="h4">
							{title}
						</Heading>
						<Box mt={16}>
							<Text as="p" size="small">
								{stripHtmlTags(description)}
							</Text>
						</Box>
					</VerticalEllipsis>
				</Text>
				<Box sx={{ display: ['none', 'block'], flex: '0 0 138px', height: DESKTOP_CONTENT_HEIGHT }} ml="auto">
					<ScrollableComponent topOffset={200}>
						<Media media={photo ? { ...photo.petitionMedium, type: 'photo' } : undefined} shape="square" />
					</ScrollableComponent>
				</Box>
			</Flex>
			{/* END: Desktop View */}
			{/* START: Mobile View */}
			<Flex sx={{ display: ['flex', 'none'], flexDirection: 'column' }} p={16}>
				<Heading as="h3" size="h4">
					{title}
				</Heading>
				<Box mt={16}>
					<ScrollableComponent topOffset={200}>
						<Media media={photo ? { ...photo.petitionMedium, type: 'photo' } : undefined} shape="rectangle" />
					</ScrollableComponent>
				</Box>
				<Box mr={[0, 16]}>
					<VerticalEllipsis
						height={96}
						ellipsis={
							<Text size="small" fontWeight="normal" color="primary-black">
								…{' '}
								<Text
									as="div"
									size="small"
									color="secondary-blue"
									sx={{
										textDecoration: 'underline',
										// eslint-disable-next-line @typescript-eslint/naming-convention
										'&:hover': { color: 'inherit' },
									}}
								>
									<Translate value="fe.pages.common.read_more" />
								</Text>
							</Text>
						}
					>
						<Box mt={16}>
							<Text as="p" size="small">
								{stripHtmlTags(description)}
							</Text>
						</Box>
					</VerticalEllipsis>
				</Box>
			</Flex>
			{/* END: Mobile View */}
			<Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }} px={16} py={8}>
				<Flex sx={{ alignItems: 'center', flex: 1 }} mr={16}>
					<Box sx={{ display: 'flex', alignItems: 'center' }} mr={8}>
						<Avatar variant="subtle" src={userPhoto} alt="" size={28} loading="lazy" />
					</Box>
					<Text size="small" lineClamp={2} fontWeight="bold" sx={{ flex: 1 }}>
						{username}
					</Text>
				</Flex>
				<Flex sx={{ alignItems: 'center' }}>
					<Flex sx={{ alignItems: 'center' }} mr={8}>
						<Icon icon={iconSupervisorAccount} size="l" color="primary-changeRed" />
					</Flex>
					<Text size="small" sx={{ flex: 1, display: ['block', 'none'] }} aria-label={supporters}>
						{supportersShort}
					</Text>
					<Text size="small" sx={{ flex: 1, display: ['none', 'block'] }}>
						{supporters}
					</Text>
				</Flex>
			</Flex>
		</RouterLink>
	);
}
