import type { FeedItem } from '../api/types';

import type { PetitionContent } from './usePetitionContent';
import { usePetitionContent } from './usePetitionContent';
import { usePetitionContext } from './usePetitionContext';
import type { PetitionContext } from './usePetitionContext/types';

type UsePetitionCardReturnType = {
	content: PetitionContent | undefined;
	context: PetitionContext | undefined;
};
export function usePetitionCard(item: FeedItem): UsePetitionCardReturnType {
	const context = usePetitionContext(item);
	const content = usePetitionContent(item);

	return { content, context };
}
