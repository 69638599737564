import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { Link as RouterLink } from '@change-corgi/core/react/router';
import { Link } from '@change-corgi/design-system/components/actions';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconComment, iconSupervisorAccount } from '@change-corgi/design-system/icons';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { linkResetStyles } from '@change-corgi/design-system/reset';
import { derivedStyle } from '@change-corgi/design-system/theme';
import { Text } from '@change-corgi/design-system/typography';

import type { FeaturedVictoryPetition } from 'src/app/pages/homepage/pageContext';

import { StarterInfo } from './StarterInfo';

export function VictoryMetadata({ victory }: { victory: FeaturedVictoryPetition }): JSX.Element {
	const starter = victory.organization || victory.user;
	return (
		<Flex sx={{ alignItems: 'center', height: 52 }}>
			<Flex py={4} px={8} sx={{ flex: 1, overflow: 'hidden', height: '100%' }}>
				<StarterInfo starter={starter} />
			</Flex>
			<Flex
				py={4}
				px={8}
				sx={{
					flex: 1,
					alignItems: 'center',
					borderLeft: derivedStyle(({ colors }) => `solid 1px ${colors['neutral-grey100']}`),
					height: '100%',
				}}
			>
				<RouterLink
					to={`/p/${victory.slug}`}
					sx={{
						...linkResetStyles,
						// eslint-disable-next-line @typescript-eslint/naming-convention
						'&:hover': { color: 'primary-changeRed' },
					}}
				>
					<Flex sx={{ alignItems: 'center' }}>
						<Icon icon={iconSupervisorAccount} mr={4} color="primary-changeRed" aria-hidden="true" />
						<Text as="div" size="small" fontWeight="bold">
							{victory.signatureState.signatureCount.displayedLocalized}
						</Text>
					</Flex>
					<Text as="div" size="small">
						<Translate value="fe.pages.home.victories.supporters" />
					</Text>
				</RouterLink>
			</Flex>
			{victory.mediaUrl && (
				<Flex
					py={4}
					px={8}
					sx={{
						flex: 1,
						alignItems: 'center',
						minWidth: 0,
						borderLeft: derivedStyle(({ colors }) => `solid 1px ${colors['neutral-grey100']}`),
						height: '100%',
					}}
				>
					<Box>
						<Flex sx={{ alignItems: 'center' }}>
							<Icon icon={iconComment} mr={4} aria-hidden="true" />
							<Text as="div" size="small">
								<Translate value="fe.pages.home.victories.featured_in" />
							</Text>
						</Flex>
						<Link
							to={victory.mediaUrl}
							sx={{
								display: 'block',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								fontWeight: 'bold',
								fontSize: 14,
							}}
						>
							{victory.mediaText}
						</Link>
					</Box>
				</Flex>
			)}
		</Flex>
	);
}
