import type { JSX } from 'react';

import { Box } from '@change-corgi/design-system/layout';

import { useViewTracking } from 'src/app/pages/homepage/shared/hooks';
import type { ViewTrackingProperties } from 'src/app/pages/homepage/shared/hooks';

import type { TrackCardClickEventData } from '../api/track';
import type { FeedItem } from '../api/types';
import { useGetCardViewData } from '../hooks/tracking/useGetCardViewData';
import { usePetitionCard } from '../hooks/usePetitionCard';
import { TYPENAME_TO_ITEM_TYPE } from '../shared/consts';

import { CardContent } from './CardContent';
import { CardHeader } from './CardHeader';
import { PromotionImpressionTracker } from './PromotionImpressionTracker';

type TrackingDataProps = Pick<TrackCardClickEventData, 'clickTarget' | 'clickTargetName' | 'contextTagId'>;

type CardProps = { item: FeedItem; onClick: (trackingData: TrackingDataProps) => void };
export function PetitionCard({ item, onClick }: CardProps): JSX.Element | null {
	const { context, content } = usePetitionCard(item);
	const eventData: ViewTrackingProperties = useGetCardViewData(item, context);

	useViewTracking({ disabled: !content, eventName: 'feed_card_show', properties: eventData });

	if (!content) return null;

	return (
		<>
			{item.__typename === 'PromotedPetitionConnection' ? (
				<PromotionImpressionTracker
					petitionId={item.node.id}
					promotionToken={item.promoteToken}
					algorithm={item.algorithm}
				/>
			) : null}
			<Box
				variant="bordered"
				sx={{
					backgroundColor: 'primary-white',
					borderColor: 'neutral-grey100',
					borderRadius: ['none', 'standard'],
					flexDirection: 'column',
				}}
				data-qa={`${TYPENAME_TO_ITEM_TYPE[item.__typename]}-discover-card`}
				data-petition-id={item.node.id}
			>
				{context && (
					<CardHeader
						icon={context.icon}
						label={context.label}
						actionText={context.actionText}
						fullHeaderLink={context.fullHeaderLink}
						href={context.href}
						onClick={() =>
							onClick({
								clickTarget: 'context',
								clickTargetName: context.contextType,
								contextTagId: context.tagId,
							})
						}
					/>
				)}
				<CardContent
					href={content.href}
					description={content.description}
					photo={content.photo}
					title={content.title}
					userPhoto={content.userPhoto}
					username={content.username}
					supporters={content.supporters}
					supportersShort={content.supportersShort}
					onClick={() =>
						onClick({
							clickTarget: 'content',
							// for the content click, the clickTargetName could be trending | promoted | recommended
							clickTargetName: TYPENAME_TO_ITEM_TYPE[item.__typename],
							contextTagId: context?.tagId,
						})
					}
				/>
			</Box>
		</>
	);
}
