import { useMemo } from 'react';

import { useHomepagePageFcm } from 'src/app/pages/homepage/pageContext';
import { useLocale } from 'src/app/shared/hooks/l10n';

type SupportAwarenessFCValue = {
	title: string;
	content: string;
	locales: readonly string[];
};

type SupportAwarenessResponse = {
	enabled: boolean;
	title?: string;
	content?: string;
};

/**
 * Show component only when the FC is enabled for the country and the
 * user's locale matches one of the allowed locales in the FC.
 */
function supportAwarenessEnabled(supportAwarenessFCValue: SupportAwarenessFCValue | undefined, userLocale: string) {
	if (!supportAwarenessFCValue || !supportAwarenessFCValue.locales.some((fcLocale) => fcLocale === userLocale)) {
		return false;
	}
	return true;
}

export function useSupportAwarenessEnabled(): SupportAwarenessResponse {
	const { homepageSupportAwareness } = useHomepagePageFcm();
	const locale = useLocale();

	return useMemo(() => {
		if (!supportAwarenessEnabled(homepageSupportAwareness, locale)) {
			return {
				enabled: false,
			};
		}
		return {
			enabled: true,
			title: homepageSupportAwareness?.title,
			content: homepageSupportAwareness?.content,
		};
	}, [homepageSupportAwareness, locale]);
}
