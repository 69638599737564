import type { JSX } from 'react';

import { useI18n } from '@change-corgi/core/react/i18n';
import { Avatar } from '@change-corgi/design-system/components/media';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { Text } from '@change-corgi/design-system/typography';

import type {
	FeaturedVictoryOrganization,
	FeaturedVictoryPhotoSize,
	FeaturedVictoryUser,
} from 'src/app/pages/homepage/pageContext';

type Starter = FeaturedVictoryOrganization | FeaturedVictoryUser;

function getStarterInfo(starter: Starter): { name?: string; photo?: FeaturedVictoryPhotoSize; countryCode?: string } {
	switch (starter.__typename) {
		case 'Organization':
			return { name: starter.name, photo: starter.photo?.organizationSmall };
		case 'User':
			return { name: starter.displayName, photo: starter.photo?.userSmall, countryCode: starter.country?.countryCode };
		default:
			// this should never happen, but Typescript will complain otherwise
			return {};
	}
}

export function StarterInfo({ starter }: { starter: Starter }): JSX.Element {
	const { getCountry } = useI18n();

	const { name, photo, countryCode } = getStarterInfo(starter);

	return (
		<Flex sx={{ alignItems: 'center' }}>
			<Box sx={{ display: 'inline-block', minWidth: 36 }} mr={4}>
				<Box
					variant="bordered"
					sx={{ borderWidth: 0, borderRadius: 2, overflow: 'hidden', display: 'flex', alignItems: 'center' }}
				>
					<Avatar variant="subtle" src={!photo || photo.processing ? undefined : photo.url} alt="" />
				</Box>
			</Box>
			<Box sx={{ overflow: 'hidden' }}>
				<Text
					as="p"
					size="small"
					fontWeight="bold"
					sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
				>
					{name}
				</Text>
				{countryCode && (
					<Text as="p" size="small" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
						{getCountry(countryCode)?.name}
					</Text>
				)}
			</Box>
		</Flex>
	);
}
