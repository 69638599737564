import { FCM_ENABLE_FOLKSONOMY_TOPICS, FCM_HOMEPAGE_SUPPORT_AWARENESS } from '@change-corgi/config/fcm/configs';

import { createFcmContext } from 'src/app/shared/contexts/fcm';
import type { LoadedStateData } from 'src/app/shared/utils/async';

export const {
	FcmContext: HomepagePageFcmContext,
	FcmProvider: HomepagePageFcmContextProvider,
	useFcm: useHomepagePageFcm,
	useAsyncFcm: useAsyncHomepagePageFcm,
	prefetchFcm: prefetchHomepagePageFcm,
} = createFcmContext({
	name: 'HomepagePageFcmContext',
	dataProperty: 'pageFcm',
	configs: {
		homepageSupportAwareness: FCM_HOMEPAGE_SUPPORT_AWARENESS,
		folksonomyTopics: FCM_ENABLE_FOLKSONOMY_TOPICS,
	},
	prefetch: true,
});

export type HomepagePageFcmState = ReturnType<typeof useAsyncHomepagePageFcm>;
export type HomepagePageFcm = LoadedStateData<HomepagePageFcmState>;
