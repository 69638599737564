import { useI18n } from '@change-corgi/core/react/i18n';
import { iconTrendingUp } from '@change-corgi/design-system/icons';

import type { FeedItemPromotedPetition } from '../../api/types';

import type { PetitionContext } from './types';

export function usePromoted(): (feedItem: FeedItemPromotedPetition) => PetitionContext {
	const { translatePlural } = useI18n();
	return (feedItem) => {
		const promoterCount = feedItem.node.promotionStats?.promoterCount || 0;
		const promoterCountLocalized = feedItem.node.promotionStats?.promoterCountLocalized || '0';

		return {
			href: `/p/${encodeURIComponent(feedItem.node.slug)}/promoters?pt=${encodeURIComponent(
				feedItem.promoteToken,
			)}&source_location=discover_feed&pt_algorithm=${encodeURIComponent(feedItem.algorithm)}`,
			icon: iconTrendingUp,
			label: `<strong>${translatePlural('fe.components.discover_feed.promoted_petitions.heading', promoterCount, {
				num: promoterCountLocalized,
			})}</strong>`,
			fullHeaderLink: false,
			contextType: 'promoted',
		};
	};
}
