import type { FeedItem } from '../../api/types';

import type { PetitionContext } from './types';
import { useFeaturedPetitions } from './useFeaturedPetitions';
import { usePromoted } from './usePromoted';

export function usePetitionContext(feedItem: FeedItem): PetitionContext | undefined {
	const getPromoted = usePromoted();
	const getFeaturedPetitionContext = useFeaturedPetitions();

	switch (feedItem.__typename) {
		case 'RecommendedPetitionConnection':
		case 'FeaturedPetitionConnection':
			return getFeaturedPetitionContext(feedItem);
		case 'PromotedPetitionConnection': {
			return getPromoted(feedItem);
		}
		default: {
			return undefined;
		}
	}
}
