import { useCallback } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

export function useTrackingActionButton(): () => void {
	const track = useTracking();

	return useCallback(() => {
		void track('sap_homepage_large_button_click', {});
	}, [track]);
}
