import { useCallback } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

import type { HomepageNoticeConfig } from './types';

type ViewEventProps = Readonly<{
	// TODO allow track() to handle object values?
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	homepageNotice: any; // HomepageNoticeConfig;
}>;

export function useHomepageNoticeClickTracking(homepageNotice: HomepageNoticeConfig): () => void {
	const track = useTracking();

	return useCallback(() => {
		void track<ViewEventProps>('homepage_notice_click', {
			homepageNotice,
		});
	}, [track, homepageNotice]);
}
