import { useEffect } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

export type ViewTrackingProperties = Record<string, number | boolean | string | undefined>;

type TrackViewProps = {
	disabled?: boolean;
	eventName: string;
	properties: ViewTrackingProperties;
};

export function useViewTracking({ disabled = false, eventName, properties }: TrackViewProps): void {
	const track = useTracking();

	// The tracking event must be called only once.
	// Later we will refactor this method with the new Context API for the event tracking.
	useEffect(() => {
		if (!disabled) {
			void track(eventName, properties);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}
