import { useCallback } from 'react';

import { useTracking } from '@change-corgi/core/react/tracking';

import { useSession } from 'src/app/shared/hooks/session';

import type { TrackCardClickEventData } from '../../api/track';
import { trackCardClick } from '../../api/track';

export function useTrackCardClick(): (trackingData: Omit<TrackCardClickEventData, 'isNewUser'>) => void {
	const track = useTracking();
	const session = useSession();
	const isNewUser = !!session?.user?.newUser;

	return useCallback(
		(trackingData) => {
			void trackCardClick({ isNewUser, ...trackingData }, track);
		},
		[isNewUser, track],
	);
}
