import { useCallback } from 'react';

import capitalize from 'lodash/fp/capitalize';

import { useI18n } from '@change-corgi/core/react/i18n';
import { iconLocalOffer } from '@change-corgi/design-system/icons';

import type { PetitionContext } from '../types';

import { useFolksonomyTopics } from './useFolksonomyTopics';

const APPROVED_TOPICS = [
	'animals',
	'criminaljustice',
	'economicjustice',
	'education',
	'environment',
	'gayrights',
	'health',
	'humanrights',
	'humantrafficking',
	'immigration',
	'food',
	'womensrights',
];

export function useTrendingInTag(): ({
	slug,
	topic,
	tagId,
}: {
	slug: string;
	topic: string;
	tagId: string;
}) => PetitionContext | undefined {
	const { translate } = useI18n();
	const folksonomyTopicsEnabled = useFolksonomyTopics();

	return useCallback(
		({ slug, topic, tagId }) => {
			if (!folksonomyTopicsEnabled) {
				if (topic && APPROVED_TOPICS.includes(topic)) {
					return {
						actionText: translate('fe.components.discover_feed.see_more'),
						href: `/t/${encodeURIComponent(slug)}?source_location=discover_feed`,
						icon: iconLocalOffer,
						label: translate(`fe.components.discover_feed.topics.${topic}`),
						fullHeaderLink: true,
						contextType: 'trending_in_tag',
						tagId,
					};
				}
				return undefined;
			}

			return {
				actionText: translate('fe.components.discover_feed.see_more'),
				href: `/t/${encodeURIComponent(slug)}?source_location=discover_feed`,
				icon: iconLocalOffer,
				label: translate('fe.components.discover_feed.topics.trending_in', {
					tag: `<strong>${capitalize(topic)}</strong>`,
				}),
				fullHeaderLink: true,
				contextType: 'trending_in_tag',
				tagId,
			};
		},
		[folksonomyTopicsEnabled, translate],
	);
}
