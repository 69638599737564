import { isLoaded } from 'src/app/shared/utils/async';

import { prefetchFcm, useFcm } from './fcm';
import type { HomepageNoticeConfig } from './types';

export function useHomepageNotice(): HomepageNoticeConfig | undefined {
	const fcm = useFcm();

	if (!isLoaded(fcm)) {
		return undefined;
	}

	if (!fcm.values.notice) {
		return undefined;
	}

	return fcm.values.notice;
}

export const prefetchHomepageNotice = prefetchFcm;
