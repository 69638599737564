import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';

import { useOrganizationSchema } from './hooks/useOrganizationSchema';

export function OrganizationSchema(): JSX.Element {
	const {
		data: { schemaJson },
	} = useOrganizationSchema();
	return (
		<Helmet>
			<script type="application/ld+json">{schemaJson}</script>
		</Helmet>
	);
}
